import * as zod from 'zod';

import { dhActuateGripperStepGenerateCommand } from '@sb/integrations/implementations/dh/comon/ui/dhActuateGripperStepGenerateCommand';
import { OnRobotDualQuickChangerCommand } from '@sb/integrations/implementations/onRobot/OnRobotDualQuickChanger/types';
import { schunkEGxActuateGripperStepGenerateCommand } from '@sb/integrations/implementations/schunk/SchunkEGx/step-wizard/schunkEGxActuateGripperStepGenerateCommand';
import type { DeviceConfiguration } from '@sb/integrations/types';
import { getEndEffector } from '@sb/integrations/utils/frontend/util';
import { ActionRequiredError, Step } from '@sb/remote-control/types';

import { CustomGripperActionConfiguration } from '../../CustomGripper/types';
import {
  GRIPPER_DIAMETER_MM_DEFAULT,
  GRIPPER_FORCE_NEWTONS_DEFAULT,
  GRIPPER_FORCE_PERCENT_DEFAULT,
  GRIPPER_GRIP_KIND_DEFAULT,
} from '../constants';
import type { ActuateGripperArguments } from '../steps/ActuateGripper/Arguments';

const TOLERANCE_DEFAULT = 0.003; // the value which was used before the new config parameter was added
const WAIT_FOR_GRIP_DEFAULT = true;
const IS_FLEX_GRIP_DEFAULT = false;

export namespace ActuateGripperStepDatabase {
  export const name = 'Actuate gripper';
  export const description = 'Open or close the gripper to pick up objects';
  export const deviceKinds = [
    'CustomGripper',
    'DHAG105145',
    'DHPGC30060',
    'DHCGI100170',
    'OnRobot2FG7',
    'OnRobot2FG14',
    'OnRobot3FG15',
    'OnRobotDualQuickChanger',
    'SchunkEGx',
  ] as const;
  export const librarySection = Step.LibrarySection.Basic;
  export const librarySort = '2';
  export const argumentKind = 'ActuateGripper';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    // for dual gripper
    selectedGripper: zod.enum(['primary', 'secondary']).default('primary'),
    diameterMM: zod.number().default(GRIPPER_DIAMETER_MM_DEFAULT),
    forceNewtons: zod.number().default(GRIPPER_FORCE_NEWTONS_DEFAULT),
    gripKind: zod
      .enum(['inward', 'outward'])
      .default(GRIPPER_GRIP_KIND_DEFAULT),
    payloadKg: zod.number().default(0),
    targetDiameterToleranceMeters: zod.number().default(TOLERANCE_DEFAULT),
    waitForGripToContinue: zod.boolean().default(WAIT_FOR_GRIP_DEFAULT),
    isFlexGrip: zod.boolean().default(IS_FLEX_GRIP_DEFAULT),
    forcePercent: zod.number().default(GRIPPER_FORCE_PERCENT_DEFAULT),
    speed: zod.number().optional(),
    // for Schunk EGK
    isSoftGrip: zod.boolean().optional(),
    // for Schunk EGU with GPE
    isStrongGrip: zod.boolean().optional(),
    // for Schunk with GPE
    activateGPE: zod.boolean().optional(),
    stopRoutineOnFailure: zod.boolean().default(true),
    action: CustomGripperActionConfiguration.nullable().optional(),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  // eslint-disable-next-line no-inner-declarations
  function convertConfigurationArgs(
    stepID: string,
    equipment: DeviceConfiguration[],
    configuration: Arguments,
  ): ActuateGripperArguments {
    const endEffector = getEndEffector(equipment);

    if (!endEffector) {
      throw new Error('Attach a gripper to utilize the Actuate Gripper step');
    }

    switch (endEffector.kind) {
      case 'OnRobot2FG7': {
        return {
          gripperCommand: {
            kind: 'OnRobot2FG7Command',
            gripKind: configuration.gripKind,
            targetForce: configuration.forceNewtons,
            targetDiameter: configuration.diameterMM / 1000,
            waitForGripToContinue: configuration.waitForGripToContinue,
            targetDiameterTolerance:
              configuration.targetDiameterToleranceMeters,
            // This is hard coded to a reasonable minimum, but could be added to FE
            // and wired through
            targetSpeed: 0.95,
          },
          stopRoutineOnFailure: configuration.stopRoutineOnFailure,
          retry: configuration.stopRoutineOnFailure,
        };
      }

      case 'OnRobot2FG14': {
        return {
          gripperCommand: {
            kind: 'OnRobot2FG14Command',
            gripKind: configuration.gripKind,
            targetForce: configuration.forceNewtons,
            targetDiameter: configuration.diameterMM / 1000,
            waitForGripToContinue: configuration.waitForGripToContinue,
            targetDiameterTolerance:
              configuration.targetDiameterToleranceMeters,
            // This is hard coded to a reasonable minimum, but could be added to FE
            // and wired through
            targetSpeed: 0.95,
          },
          stopRoutineOnFailure: configuration.stopRoutineOnFailure,
          retry: configuration.stopRoutineOnFailure,
        };
      }

      case 'OnRobot3FG15': {
        return {
          gripperCommand: {
            kind: 'OnRobot3FG15Command',
            gripKind: configuration.gripKind,
            targetForce: configuration.forceNewtons,
            targetDiameter: configuration.diameterMM / 1000,
            targetDiameterTolerance:
              configuration.targetDiameterToleranceMeters,
            isFlexGrip: configuration.isFlexGrip,
            waitForGripToContinue: configuration.waitForGripToContinue,
          },
          stopRoutineOnFailure: configuration.stopRoutineOnFailure,
          retry: configuration.stopRoutineOnFailure,
        };
      }

      case 'OnRobotDualQuickChanger': {
        if (
          endEffector.grippers[configuration.selectedGripper].kind ===
          'NoGripper'
        ) {
          throw new ActionRequiredError({
            kind: 'missingEquipment',
            message: `The ${configuration.selectedGripper} gripper is not configured`,
          });
        }

        const { gripperCommand } = convertConfigurationArgs(
          stepID,
          [endEffector.grippers[configuration.selectedGripper]],
          configuration,
        );

        const parsedSubCommand =
          OnRobotDualQuickChangerCommand.shape.command.safeParse(
            gripperCommand,
          );

        if (!parsedSubCommand.success) {
          throw new ActionRequiredError({
            kind: 'missingEquipment',
            message: `Sub-command given to Dual quick changer is not supported: ${parsedSubCommand.error.toString()}`,
          });
        }

        return {
          gripperCommand: {
            kind: 'OnRobotDualQuickChangerCommand',
            active: configuration.selectedGripper,
            command: parsedSubCommand.data,
          },
          stopRoutineOnFailure: configuration.stopRoutineOnFailure,
          retry: configuration.stopRoutineOnFailure,
        };
      }

      case 'DHAG105145':
      case 'DHPGC30060':
      case 'DHCGI100170': {
        return {
          gripperCommand: dhActuateGripperStepGenerateCommand(
            `${endEffector.kind}Command` as const,
            endEffector,
            configuration,
          ),
          stopRoutineOnFailure: configuration.stopRoutineOnFailure,
        };
      }

      case 'SchunkEGx':
        return {
          gripperCommand: schunkEGxActuateGripperStepGenerateCommand(
            endEffector,
            configuration,
          ),
          stopRoutineOnFailure: configuration.stopRoutineOnFailure,
        };

      case 'CustomGripper':
        return {
          gripperCommand: {
            kind: 'CustomGripperCommand',
            action: configuration.action as CustomGripperActionConfiguration,
          },
          stopRoutineOnFailure: configuration.stopRoutineOnFailure,
        };

      default: {
        throw new ActionRequiredError({
          kind: 'missingEquipment',
          message: `Gripper ${endEffector.kind} is not supported for this step`,
        });
      }
    }
  }

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
    equipment,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    return {
      ...stepData,
      stepKind: 'ActuateGripper',
      args: convertConfigurationArgs(
        stepData.id,
        equipment.map(({ config }) => config),
        args,
      ),
    };
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
    includeStepName,
    distanceUnitInfo,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      return null;
    }

    // TODO: we want to change the step name to "Actuate Primary Gripper"
    // if we're using a dual gripper - that check needs to happen elsewhere
    // as this only affects the description text

    const { gripKind, diameterMM, waitForGripToContinue, isFlexGrip } = args;

    const finalString = `to ${distanceUnitInfo ? distanceUnitInfo.toString(diameterMM / 1000) : `${diameterMM} millimeters`}`;

    const sentenceBody = [
      isFlexGrip || waitForGripToContinue ? 'to grasp object' : false,
      gripKind === 'inward' ? false : 'moving outward',
      isFlexGrip ? false : finalString,
    ]
      .filter((item) => item !== false)
      .join(' ');

    return `${includeStepName ? 'Actuate gripper ' : ''}${sentenceBody}`;
  };
}

ActuateGripperStepDatabase satisfies Step.StepKindInfo;
