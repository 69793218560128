import * as zod from 'zod';

import { CustomGripperActionConfiguration } from './CustomGripperConfiguration';

export const CustomGripperCommand = zod.object({
  kind: zod.literal('CustomGripperCommand'),

  action: CustomGripperActionConfiguration,
});

export type CustomGripperCommand = zod.infer<typeof CustomGripperCommand>;
