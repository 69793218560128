import * as zod from 'zod';

import {
  CustomDeviceActionConfiguration,
  CustomDeviceConfiguration,
} from '@sb/integrations/implementations/CustomDevice/types/CustomDeviceConfiguration';
import { ActionRequiredError, Step } from '@sb/remote-control/types';

export namespace OperateEquipmentStep {
  export const name = 'Operate Equipment';
  export const description =
    "Communicate with other devices defined in this robot's Equipment.";
  export const librarySection = Step.LibrarySection.InterfaceWithMachines;
  export const argumentKind = 'OperateEquipment';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    equipment: CustomDeviceConfiguration.optional(),
    action: CustomDeviceActionConfiguration.optional(),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    if (args.equipment == null) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Equipment required',
        fieldId: 'equipment',
      });
    }

    if (args.action == null) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Action required',
        fieldId: 'action',
      });
    }

    return {
      ...stepData,
      stepKind: 'OperateEquipment',
      args: {
        equipment: args.equipment,
        action: args.action,
      },
    };
  };
}

OperateEquipmentStep satisfies Step.StepKindInfo;
