import * as zod from 'zod';

import { CartesianPose, ZERO_POSE } from '@sb/geometry';
import {
  IntegrationInterfaceKind,
  IntegrationControlBoxIOInterface,
  IntegrationFlangeIOInterface,
} from '@sb/integrations/io/types';
import {
  CustomEquipmentActionConfiguration,
  CustomEquipmentSensorConfiguration,
  CustomEquipmentConfiguration,
} from '@sb/integrations/types/customEquipmentTypes';

export const CustomGripperTooltipConfiguration = zod.object({
  name: zod.string().default('Tooltip'),
  tcpTransform: CartesianPose.default(ZERO_POSE),
});

export type CustomGripperTooltipConfiguration = zod.infer<
  typeof CustomGripperTooltipConfiguration
>;

// Extend CustomEquipmentActionConfiguration for CustomGripper
export const CustomGripperActionConfiguration =
  CustomEquipmentActionConfiguration.extend({
    // Add any CustomGripper-specific action fields here if needed
  });

export type CustomGripperActionConfiguration = zod.infer<
  typeof CustomGripperActionConfiguration
>;

// Use CustomEquipmentSensorConfiguration for CustomGripper
export const CustomGripperSensorsConfiguration =
  CustomEquipmentSensorConfiguration;

export type CustomGripperSensorsConfiguration = zod.infer<
  typeof CustomGripperSensorsConfiguration
>;

// CustomGripper Configuration
export const CustomGripperConfiguration = CustomEquipmentConfiguration.extend({
  kind: zod.literal('CustomGripper'),
  name: zod.string().default('Custom gripper'),
  sensors: zod.array(CustomGripperSensorsConfiguration).default([]),
  actions: zod.array(CustomGripperActionConfiguration).default([
    CustomGripperActionConfiguration.parse({
      name: 'Actuate',
      kind: 'actuate',
      actionOutputs: {
        kind: IntegrationInterfaceKind.Values.controlBoxIO,
        outputs: [],
        pulseSeconds: 0.5,
      },
    }),
    CustomGripperActionConfiguration.parse({
      name: 'Reset',
      kind: 'reset',
      actionOutputs: {
        kind: IntegrationInterfaceKind.Values.controlBoxIO,
        outputs: [],
        pulseSeconds: 0.5,
      },
    }),
  ]),
  controlInterfaces: zod
    .array(
      zod.union([
        IntegrationControlBoxIOInterface,
        IntegrationFlangeIOInterface,
      ]),
    )
    .nullable()
    .default(null),
  tooltips: zod
    .array(CustomGripperTooltipConfiguration)
    .default([CustomGripperTooltipConfiguration.parse({})]),
});

export type CustomGripperConfiguration = zod.infer<
  typeof CustomGripperConfiguration
>;
