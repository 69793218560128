import * as zod from 'zod';

import {
  IntegrationActionKind,
  IntegrationActionControlBoxIOInterface,
  IntegrationActionFlangeIOInterface,
  IntegrationSensorControlBoxIOInterface,
  IntegrationSensorFlangeIOInterface,
  IntegrationActionFlangeIOOutputs,
  IntegrationActionControlBoxIOOutputs,
  IntegrationControlBoxIOInterface,
  IntegrationFlangeIOInterface,
} from '@sb/integrations/io/types';

export const CustomEquipmentControlInterfaceConfiguration = zod.union([
  IntegrationControlBoxIOInterface,
  IntegrationFlangeIOInterface,
]);

export type CustomEquipmentControlInterfaceConfiguration = zod.infer<
  typeof CustomEquipmentControlInterfaceConfiguration
>;

export const CustomEquipmentIntegrationActionOutputs = zod.union([
  IntegrationActionFlangeIOOutputs,
  IntegrationActionControlBoxIOOutputs,
]);

export type CustomEquipmentIntegrationActionOutputs = zod.infer<
  typeof CustomEquipmentIntegrationActionOutputs
>;

export const CustomEquipmentActionOutputsConfiguration = zod
  .union([
    IntegrationActionControlBoxIOInterface,
    IntegrationActionFlangeIOInterface,
  ])
  .nullable()
  .default(null);

export type CustomEquipmentActionOutputsConfiguration = zod.infer<
  typeof CustomEquipmentActionOutputsConfiguration
>;

export const CustomEquipmentActionConfiguration = zod.object({
  kind: IntegrationActionKind,
  name: zod.string().default('Action'),
  customActionName: zod.string().default(''),
  actionOutputs: zod
    .union([
      IntegrationActionControlBoxIOInterface,
      IntegrationActionFlangeIOInterface,
    ])
    .nullable()
    .default(null),
});

export type CustomEquipmentActionConfiguration = zod.infer<
  typeof CustomEquipmentActionConfiguration
>;

// Generic Sensor Configuration
export const CustomEquipmentSensorConfiguration = zod
  .union([
    IntegrationSensorControlBoxIOInterface,
    IntegrationSensorFlangeIOInterface,
  ])
  .nullable()
  .default(null);

export type CustomEquipmentSensorConfiguration = zod.infer<
  typeof CustomEquipmentSensorConfiguration
>;

// Generic Equipment Configuration
export const CustomEquipmentConfiguration = zod.object({
  kind: zod.string(),
  name: zod.string(),
  sensors: zod.array(CustomEquipmentSensorConfiguration).default([]),
  actions: zod.array(CustomEquipmentActionConfiguration).default([]),
});

export type CustomEquipmentConfiguration = zod.infer<
  typeof CustomEquipmentConfiguration
>;
